<template>
 <el-dialog
      :visible.sync="visible"
      width="60%"
      @close="closeDialog"
    >
      <el-row>
        <el-col>
          <span>是否清空数据</span>
        </el-col>
      </el-row>
      <el-row gutter="50" style="margin-top: 30px">
        <el-col>
          <el-button size="small" @click="closeDialog()">取消</el-button>
          <el-button size="small" type="primary" @click="resetForm('')">确定</el-button>
        </el-col>
      </el-row>
    </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("update:visible", false);
    },
    resetForm() {
      this.$emit("reset-form", '');
      this.visible = false;
    }
  },
};
</script>

<style scoped>
.edit-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
