<template>
  <!-- 替换文本 -->
  <el-dialog :visible.sync="visible" width="100%" @close="closeDialog">
    <el-row>
      <el-col :offset="1" :span="22" class="edit-col">
        <el-input v-model="replaceOri" size="mini"></el-input>
        <span width="10%">&nbsp&nbsp&nbsp&nbsp</span>
        <el-button type="success" size="mini" @click="replaceTextWithInput()"
          >替换</el-button
        >
        <span width="10%">&nbsp&nbsp&nbsp&nbsp</span>
        <el-input v-model="replaceTarget" size="mini"></el-input>
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-input
        type="textarea"
        @dblclick="selectText"
        v-model="replaceText"
        placeholder=" "
        style="font-size: 14px"
        :rows="10"
        :autosize="{ minRows: 6, maxRows: 40 }"
      ></el-input>
    </el-row>

    <el-row gutter="50" style="margin-top: 10px">
      <el-col class="edit-col" span="20" offset="2">
        <el-button size="small" type="success" @click="getPaste()">粘贴</el-button>
        <el-button size="small" type="success" @click="copyReplaceText()">复制</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    replaceText: {
      type: String,
      default: "",
    },
    selectText: {
      type: String,
      default: "",
    },
    replaceOri: {
      type: String,
      default: "",
    },
    replaceTarget: {
      type: String,
      default: "",
    },
  },
  methods: {
    closeDialog() {
      this.$emit("update:visible", false);
    },
    async getPaste() {
      var val = await navigator.clipboard.readText();
      if (val !== undefined && val !== "") {
        this.replaceText = val;
        this.$message({
          type: "info",
          message: "黏贴成功",
        });
      } else {
        this.$message({
          type: "info",
          message: "无内容黏贴",
        });
      }
    },

    replaceTextWithInput() {
      this.replaceText = this.replaceText.replaceAll(this.replaceOri, this.replaceTarget);
    },

    copyReplaceText() {
      var self = this;
      this.$copyText(this.replaceText).then(
        function (e) {
          self.$message({
            type: "info",
            message: "复制成功",
          });
        },
        function (e) {
          self.$message({
            type: "info",
            message: "复制失败" + e,
          });
        }
      );
    },
  },
};
</script>

<style scoped>
.edit-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
