<template>
  <el-dialog :visible.sync="visible" width="100%" @close="closeCustDialog" class="pg">
    <el-table
      :data="tmpCustTable"
      max-height="50%"
      :header-cell-style="biggerHeaderCellStyle"
      border
      size="mini"
      :row-style="{ height: '5px' }"
      :cell-style="{ padding: '0' }"
      style="font-size: 8px"
    >
      <el-table-column label="删除" align="center">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index, tmpCustTable)"
            icon="el-icon-delete-solid"
            size="small"
          >
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="客户/上家" align="center">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="请选择"
            :disabled="isClzDisable(scope.row.password)"
          >
            <el-option
              v-for="item in custOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              size="mini"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="cust" label="用户名称" align="center">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.cust"
            size="mini"
            controls-position="right"
            :disabled="isClzDisable(scope.row.password)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="g" :label="pageInfo.gv" align="center">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.g"
            size="mini"
            controls-position="right"
            :disabled="isClzDisable(scope.row.password)"
          />
        </template>
      </el-table-column>

      <el-table-column prop="z" :label="pageInfo.zv" align="center">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.z"
            size="mini"
            controls-position="right"
            :disabled="isClzDisable(scope.row.password)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer" align="center">
      <!-- 左边的加号按钮 -->
      <el-button
        size="small"
        type="success"
        @click="addClzRow(tmpCustTable)"
        class="title"
        >新增</el-button
      >
      <!-- 右边的编辑按钮 -->
      <!-- <el-button size="small" type="success" class="title" @click="submitCustForm()" width="100%">清除设置</el-button> -->
      <el-button
        size="small"
        type="success"
        class="title"
        @click="submitCustForm()"
        width="100%"
        >保存设置</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { saveCust } from "../api/parseContent.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    clzType: '',
    tmpCustTable: [],
    pageInfo: {},
    custOptions: [],
  },
  methods: {
    closeCustDialog() {
      this.$emit("update:visible", false);
    },
    isClzDisable(value) {
      return value !== null && value !== undefined && value !== "";
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    addClzRow(rows) {
      rows.push({
      });
    },
    biggerHeaderCellStyle({ row, column, rowIndex, columnIndex }) {
      return { fontSize: "12px", background: "#67c23a", color: "white" };
    },
    submitCustForm() {
      let token = localStorage.getItem("token");
      saveCust({
        token: token,
        clz: this.clzType,
        userClzs: this.tmpCustTable,
      }).then((res) => {
        if (res.data.code == 999) {
          this.$message({
            type: "info",
            duration: 500,
            message: res.msg,
          });
          this.$router.push("/"); //登录验证成功路由实现跳转
          return;
        }
        this.$message({
          type: "info",
          duration: 500,
          message: "保存成功",
        });
        this.$emit("submit-cust-form", true);
        this.visible = false;
      });
    },
  },
};
</script>

<style scoped>
.edit-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .pg .el-dialog__body {
  /* font-size: 9px; */
  /* line-height: 10px; */
  padding: 0;
  padding-top: 10px;
  /* text-align: center; */
  /* 根据需要调整文本大小 */
}
</style>
