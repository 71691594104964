<template>
  <!-- 替换文本 -->

  <el-dialog
    :visible.sync="visible"
    width="100%"
    @close="closeClzDialog"
    style="padding-left: 10%; padding-right: 10%"
  >
    <el-table :data="tmpClzTable" style="width: 100%" max-height="50%" border>
      <el-table-column label="删除" align="center">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index, tmpClzTable)"
            icon="el-icon-delete-solid"
            size="small"
          >
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="clz" :label="pageInfo.km" align="center">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.clz"
            size="small"
            controls-position="right"
            :disabled="isClzDisable(scope.row.password)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer" align="center">
      <!-- 左边的加号按钮 -->
      <el-button
        size="small"
        type="success"
        @click="addClzRow(tmpClzTable)"
        class="title"
        >{{ pageInfo.addkm }}</el-button
      >
      <!-- 右边的编辑按钮 -->
      <el-button
        size="small"
        type="success"
        class="title"
        @click="submitClzForm()"
        width="100%"
        >保存</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import {
  getClz,
  saveClz,

} from "../api/parseContent.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    tmpClzTable: [],
    pageInfo: {},
  },
  methods: {
    closeClzDialog() {
      this.$emit("update:visible", false);
    },
    isClzDisable(value) {
      return value !== null && value !== undefined && value !== "";
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    addClzRow(rows) {
      rows.push({});
    },
    submitClzForm() {
      let token = localStorage.getItem("token");
      saveClz({
        token: token,
        userClzs: this.tmpClzTable,
      }).then((res) => {
        //console.log("saveclz");
        //console.log(res);
        if (res.data.code == 999) {
          this.$message({
            type: "info",
            duration: 500,
            message: res.msg,
          });
          this.$router.push("/"); //登录验证成功路由实现跳转
          return;
        }
        if (res.data.code == 0) {
          this.$message({
            type: "info",
            duration: 500,
            message: "保存成功",
          });
          this.$emit("submit-clz-form", true);
          this.visible = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.edit-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
